// Variable overrides
$body-bg: #f2f4f9;
$body-color: #222;

$theme-colors: (
  "primary": rgba(83, 83, 83, 0.9),
  "success": #0baaa5,
  "warning": #f8b31c,
  "danger": #f5357ffd,
  "info": #1d8cfa,
);
