// Here you can add other styles
// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");
// Import react-toastify
@import "react-toastify/dist/ReactToastify.css";

body {
  font-family: Exo, Roboto, sans-serif;
}

// login
.login.login-wrapper {
  display: flex;

  .login-left {
    flex: 1;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%),
      url("../assets/prison1.jpg") center no-repeat;
  }

  .login-right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 30%;
    min-width: 320px;
    height: 100vh;

    background: #fff;

    .login-form {
      display: grid;
      justify-content: center;
      // grid-template-rows: repeat(6, auto);
      row-gap: 8px;
      grid-template-columns: 80%;

      grid-template-areas:
        "heading "
        "sub-heading "
        "email "
        "password "
        "forgot"
        "login";

      .heading {
        grid-area: heading;
      }

      .sub-heading {
        grid-area: sub-heading;
        font-size: 16px;
      }

      .email {
        grid-area: email;

        &-input {
          border-width: 0;
          box-shadow: inset 0 0.5px 1px 1px var(--secondary),
            inset 0 -2px 2px 2px #fff;
        }
      }

      .password {
        grid-area: password;

        &-input {
          border-width: 0;
          box-shadow: inset 0 0.5px 1px 1px var(--secondary),
            inset 0 -2px 2px 2px #fff;
        }
      }

      .login-button {
        grid-area: login;
      }

      .forgot-password {
        grid-area: forgot;
        justify-self: flex-end;
      }
    }
  }
}

//register
.register.register-wrapper {
  display: flex;

  .register-left {
    flex: 1;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%),
      url("../assets/prison2.jpg") top center no-repeat;
  }

  .register-right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 30%;
    min-width: 320px;
    height: 100vh;

    color: #fff;

    background: rgba(83, 83, 83, 0.9);

    .register-form {
      display: grid;
      justify-content: center;
      // grid-template-rows: repeat(6, auto);
      row-gap: 8px;
      grid-template-columns: 80%;

      grid-template-areas:
        "heading"
        "sub-heading"
        "first-name"
        "last-name"
        "email"
        "password"
        "confirm-password"
        "register";

      .heading {
        grid-area: heading;
      }

      .sub-heading {
        grid-area: sub-heading;
        font-size: 16px;
        color: #fff !important;
      }

      .first-name {
        grid-area: first-name;
      }

      .last-name {
        grid-area: last-name;
      }

      .email {
        grid-area: email;

        &-input {
          border-width: 0;
          box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
        }
      }

      .password {
        grid-area: password;

        &-input {
          border-width: 0;
          box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
        }
      }

      .confirm-password {
        grid-area: confirm-password;
      }

      .register-button {
        grid-area: register;
      }
    }
  }
}

// sidebar theme
.c-sidebar {
  background: rgba(83, 83, 83, 0.9) !important;
}

// react toastify
.Toastify__toast--success {
  background-color: #fff !important;
  color: #222;
  font-family: Exo;

  &::before {
    content: "Success";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: block;

    width: 100%;
    padding: 0 8px;

    font-family: Exo;
    font-size: 14px;
    color: #fff;
    background-color: var(--success) !important;
  }
}

.Toastify__toast--error {
  background-color: #fff !important;
  color: #222;
  font-family: Exo;

  &::before {
    content: "Error";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: block;

    width: 100%;
    padding: 0 8px;

    font-family: Exo;

    font-size: 14px;
    color: #fff;
    background-color: var(--danger) !important;
  }
}

.Toastify__toast--warning {
  background-color: #fff !important;
  color: #222;
  font-family: Exo;

  &::before {
    content: "Warning";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: block;

    width: 100%;
    padding: 0 8px;

    font-family: Exo;

    font-size: 14px;
    color: #fff;
    background-color: var(--warning) !important;
  }
}

.Toastify__toast-body {
  padding-top: 22px;
}

.Toastify__close-button {
  transform: translateY(-40%);

  z-index: 3;
}

// sweet alert
.swal2-title {
  font-size: 1.09375rem !important;
  padding: 0 !important;
  color: #fff !important;
}

.swal2-close {
  font-size: 1.3125rem !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  opacity: 0.5 !important;
  color: #222 !important;
  text-shadow: 0 1px 0 #fff !important;
  transform: translate(-50%, 50%) !important;

  &:hover {
    transform: translate(-50%, 50%) !important;
  }
}

.delete-confirm-modal .swal2-actions .swal2-confirm {
  &:focus {
    color: #fff;
    background-color: rgba(243, 17, 104, 0.9921568627);
    border-color: rgba(235, 12, 98, 0.9921568627);
    box-shadow: 0 0 0 0.2rem rgb(247 84 146 / 50%);
  }
}

.reset-confirm-modal .swal2-actions .swal2-confirm {
  &:focus {
    color: #4f5d73;
    background-color: #e7a107;
    border-color: #da9807;
    box-shadow: 0 0 0 0.2rem rgb(223 166 41 / 50%);
  }
}

// applies to all table row hover bg color, but only with the direct child
.table.table-hover tbody > tr {
  &:hover {
    background-color: rgba(111, 111, 114, 0.06);
  }
}

// Expense list show collapse
.expenses-list-data-table {
  tr.p-0 {
    &:hover {
      background-color: transparent !important;
    }
  }
}

// Material Table
// table font family and background color at the highest level
.MuiPaper-root {
  font-family: Exo !important;
  background-color: #f2f4f9 !important;

  &.MuiDialog-paper.MuiPickersModal-dialogRoot {
    background-color: #fff !important;
  }
}

//actions column center
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone {
  & > div {
    justify-content: center;
  }
}

// tooltip styling
.MuiTooltip-tooltip {
  max-width: 200px !important;
  font-family: Exo !important;
  background-color: #000015 !important;
  font-size: 0.765625rem !important;
  padding: 0.25rem 0.5rem !important;
}

// header color transparnet
.MuiTableHead-root {
  .MuiTableRow-root.MuiTableRow-head {
    th {
      background-color: transparent !important;
      span {
        font-family: Exo !important;
        font-weight: 600 !important;
      }
    }
  }
}

// filter styling
.MuiTableBody-root {
  .MuiFormControl-root.MuiTextField-root {
    height: 32px !important;

    float: none !important;

    input {
      font-size: 14px !important;
      font-family: Exo !important;
    }
  }
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline {
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    font-size: 14px !important;
    font-family: Exo !important;
  }
}

.MuiTableFooter-root {
  .MuiTableRow-footer {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.MuiInputBase-root.MuiTablePagination-input {
  /* Class generated from your code */
  display: none !important;
}

.form-edit-client {
  position: relative;
  .spinner-grow {
    position: absolute;
    top: 45%;
    left: 45%;

    // transform: translateY(-50%);
  }
}

.jaylabels:hover {
  cursor: pointer;
  background-color: wheat;
}
